body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.template {
    min-height: 100vh; /* Evita que a header seja empurrada para fora */
    background-color: #1c2b6cf9;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  
}
/* Container geral */
.div-geral-carrossel {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   
  
}

/* Alinha imagem e texto lado a lado */
.content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    grid-gap: 20px;
    gap: 20px;
    
}

/* Imagem ocupa metade da div e fica dentro do template */
.img-container {
   
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
   
}

/* Faz a imagem se ajustar corretamente */
.img-carrossel {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajusta a imagem para cobrir a área sem distorcer */
    max-width: 100%;
    max-height: 100%;
    
}

/* Texto ocupa a outra metade */
.text-container {
   
  
    text-align: center;
    padding: 20px;
}
.text-container h2 {
    margin: 10px 0;
  }
  
.text1-carrossel {
    
    font-size: 50px;
    font-weight: 800;
        
}

.text2-carrossel {
   
    font-size: 60px;
    font-weight: 800;
   
    
}

.text3-carrossel {
    
    font-size: 40px;
     
}

.btn-home {
    color: #ffffff;
    font-weight: 700;
    background-color: #b22234;
    border: 10px groove rgba(170, 36, 53, 1);
    border-radius: 25px;
    box-shadow: 0px 10px 28px 10px rgba(255, 255, 255, 1);
    width: 18.75rem;
    height: 7.5rem;
    margin-top: 2rem;
}

.btn-home a {
    font-size: 2em;
    text-decoration: none;
    color: #ffffff;
}

.btn-home:hover {
    background-color: #ffffff;
    border: 10px groove #ece6e7;
}

.btn-home:hover a {
    color: #b22234;
}

#btn-flutuante{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0.5em;
    bottom: 0.5em;
    z-index: 1;
    cursor: pointer;
    text-align: center;
    font-size: 4em;
    padding: 5px;
    transition: font-size 0.5s;
}

#btn-flutuante a{
    text-decoration: none;
    color: #ffffff;
}

#btn-flutuante:hover{
   font-size: 5em;   
}


@media (max-width: 800px) {

    .div-geral-carrossel {
      flex-wrap: wrap;
       
      
    }
    
    
   
    .text1-carrossel {
        font-size: 30px;
        text-align: center;
    }
    .text2-carrossel {
        font-size: 40px;
        text-align: center;
    }
    .text3-carrossel {
        font-size: 20px;
        text-align: center; 
    }

    .btn-home {
        color: #ffffff;
        font-weight: 700;
        background-color: #b22234;
        border: 10px groove rgba(170, 36, 53, 1);
        border-radius: 25px;
        box-shadow: 0px 10px 28px 10px rgba(255, 255, 255, 1);
        width: 18rem;
        height: 7.5rem;
        margin-top: 2rem;
    }
    
   
    

}


*{
    margin: 0;
    padding: 0;
}

/* ----- Header ----- */

.header{
    background-color: aliceblue;
    opacity: 0.8;
    width: 100%;
}
 
.div-logo{
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    line-height: 10px;
    text-align: center;
    align-items: center;
}

.fonte{
    width: 250px;
    padding: 10px;
    
}


.me-auto {
   margin-left:30% ;    
   grid-gap: 20px;    
   gap: 20px;
   font-weight: 700;

}

.nav-link{
    color: rgba(0, 0, 0, 0.986);
  
}

.nav-link a{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.884);
    border-bottom: 2px solid rgba(0,0,0,.55);
}
.nav-link a:hover{
    text-decoration: none;
    color: rgb(66, 64, 64);
    
}

.img-sociais{
    width: 50px;
    cursor: pointer;
    
}

.p-sociais{
    font-weight: 700;
    font-size: 14px;
    margin-left: 20px;
}


.nav-link .active {
    text-decoration: none;
    color: #b22234!important; 
    border-bottom: 2px solid #b22234!important;
}

@media (max-width: 990px) {
.fonte{
   width: 180px;
   height: 100px;
}
.me-auto{
    background-color: #ffffff94;
    border-radius: 10px;
    text-align: center;
}
.img-sociais{
    width: 70px;
    cursor: pointer;
    
}

}






.sobre {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 50px 40px;
    

}

.sobre>h2 {
    font-family: 'Rowdies', cursive;
    font-size: 30px;
    color: #ffffff;
    line-height: 1.8;
    border-bottom: 2px solid #ffffff;
    width: 250px;
}

.text-sobre {
    display: flex;
    align-items: center;
    justify-content: center;
  

}

.text {
    
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 50px;
    border-radius: 20px;
}

.text-h1{
    position: relative;;
    font-family: 'Rowdies', cursive;
    align-items: center;
    text-align: center;
    font-size: 4rem;
    
    
}

.text-h1::before{
position: absolute;
content: '';
width: 60px;
height: 5px;
background-color: #1c2b6c;
bottom: 4px;

}


.img-daize {
    width: 30rem;
    height: 30rem;
    border-radius: 20px;

}

.button-sobre-mim {
    color: #fff;
    font-weight: 700;
    background-color: #1c2b6c;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 200px;
    height: 75px;

}

.button-sobre-mim:hover {
    color: #fff;
    background-color: #72131e;
}

@media (max-width: 800px) {

    .img-daize {
        width: 20rem;
        height: 20rem;
        border-radius: 20px;
    
    }

    .text-h1{
        margin-top: 20px;
        font-size: 2rem;
        
        
    }
    .text-h1::before{
       
        bottom: 1px;
        
        }
}

.footer {
    background-color: #1c2b6c;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: relative;
    width: 100%;
    bottom: 0;
    
}
.redes-sociais>a{
  color: #FFFFFF;
  margin: 15px;
  font-size: 25px;
}

.p-sociais-footer{
  font-weight: 700;
  font-size: 16px;
  margin-left: 20px;
}

#link-topo{
  width: 50px;
  height: 50px;
  text-decoration: none;
  color: #FFFFFF;
  background-color: #1c2b6c;
  font-size: 2rem;
  position: absolute;
  right: 10px;
  bottom: 250px;
  border-radius: 20%;
  text-align: center;
  font-weight: 700;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}


.employment{
    margin-top: 20px;
    
}

.employment p{
    
    margin-bottom: 2px;
    font-size: 12px;
}


.motivacao {
  background-color: #ffff;
  padding: 50px;
  word-break: break-word;
}

.motivacao > h1 {
  font-family: "Rowdies", cursive;
  font-size: 3rem;
  text-align: center;
}

.motivacao-h1 {
  font-family: "Rowdies", cursive;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 50px;
  color: #b22234;
}
.container-motivacao{
   display: flex;
   align-items: center;
   justify-content: center;
}

.row-container{
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: center;

 

}

.img-motivacao,
.caption-motivacao {
  display: inline-block;
  padding: 10px;
 
}

.caption-motivacao{
   width: 200px;
} 

.img-figura {
  
  box-shadow: 2px 2px 2px 0 #1a12132a;
  border-radius: 20px;
  margin-left: 80px;
  font-family: "Rubik", sans-serif;
  
} 

.div-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.button-motivacao {
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
  background-color: #b22234;
  border: 2px solid #ffffff;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 84px;
  margin-left: 30px;
}

.button-motivacao a {
  text-decoration: none;
  color: #ffffff;
}

.button-motivacao:hover {
  background-color: #b22234;
  border: 2px solid #ffffff;
}
.button-motivacao:hover a {
  color: #ffffff;
}

@media (max-width: 800px) {
  .img-figura {
    margin-right: 80px;
   }
   .row-container{
   
    text-align: center;
   }
}
.metodologia{
   display: flex;
   flex-direction: column;
   height: 100%;
   width: 100%;
   padding: 50px 40px;
   background-size: cover;
  
   
   }

   .logo-biblia{
    width: 330px;
   
   }
   .metodologia>h1{
      font-family: 'Rowdies', cursive;
      text-align: center;
      
   }
        
   .metodologia>p{
      position: relative;
      font-family: 'Rowdies', cursive;
      text-align: center;
      font-size: 1.2rem;
     
   }

   .metodologia>p::before{
      position: absolute;
      content: '';
      width: 95px;
      height: 3px;
      background-color: black;
      bottom: -10px;
      margin-left: 125px;
   }
   .video-youtube-metodologia{
      display: flex;
      justify-content: center;
      padding-top: 50px;
   }
  
    .button-ingles{
      color: #b22234;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border: 2px solid #b22234;
      border-radius: 40px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      width: 200px;
      height: 84px;
      margin-top: 30px;
           
   }

   .button-ingles a{
      text-decoration: none;
      color: #b22234;
         
   }
   
   .button-ingles:hover{
      background-color: #b22234;
      border: 2px solid #ffffff;
      color: #ffffff;
   }
   .button-ingles:hover a{
      color: #ffffff;
   }

   .text1{
      padding-top: 50px;
      font-family: 'Rowdies', cursive;

   }

   @media (max-width: 800px) {
      .metodologia>h1{
        display: flex;
        justify-content: center;
        align-items: center;
         font-size: 2rem;
         margin-left: 2px;
         
      }
      .metodologia>{
       text-align: center;
      
       }
       .video-youtube-metodologia{
         width: 18rem;
       }

       .react-player{
         width:300px;
         height: 300px;
       }
  
  }
   
.comentarios {
    display: flex;
    align-items: center;
    padding: 50px 40px;
    background-color:  #acb5df75;
    justify-content:space-around;
    flex-wrap: wrap;
       
}

.comentarios>h1 {
    display: flex;
    position: relative;
    font-family: 'Rowdies', cursive;
    font-size: 3rem;
    text-align: center;
    align-items: center;
    
}
.comentarios>h1::before{
    content: "";
    position: absolute;
    background-image: url(/static/media/Aspas.a5ab04cd.png);
    height: 200px;
    width: 235px;
    inset: 0;
    background-size: contain;
    z-index: -1;
    opacity: 0.7;
    
    
   
}

.comments-carrossel {
    display: flex;
    flex-wrap: nowrap; /* Impede que os itens quebrem a linha */
    width: 50%;
    padding: 20px;
    justify-content: flex-start;
    
}

.card-comentarios {
    height: 400px;
    margin-right: 15px; /* Espaço entre os cartões */
    padding: 20px;
    background: #1c2b6c;
    font-family: 'Rowdies', cursive;
    color: #ffff;
     /* Impede que os cartões se estiquem */
    
}
.estados{
    font-size: 15px;
}
.img-estrela{
    width: 100px;
    margin-top: -30px;
}

.comment-p {
    padding-top: 20px;
    color: #ffff;
    text-align: left;
}

*::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #DC0B0B;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #D50505;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #1712A3;
} 

@media (max-width: 800px) {
    .comments-carrossel {
        width: 250px;
    }
    .text-comentarios {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        height: 110px;
    }

   } 
.youtube-sessao{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding:40px;
    background-color: #acb5df75;
    color: #1c2b6c;
    
}

.youtube-sessao h1{
    position: relative;
    font-family: 'Rowdies', cursive;
    font-size: 3rem;
    text-align: center;
   
}

.youtube-container{
    display: flex;
    flex-direction: column;
    
}

.youtube-videos{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding-top: 50px;


}

iframe{
    border-radius: 20px;
}

.button-youtube{
color: #ffffff;
font-weight: 700;
background-color: #b22234;
border: 2px solid #ffffff;
border-radius: 30px;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
width: 200px;
height: 84px;
margin-top: 50px;

}

.button-youtube:hover{
border: none;
color: #fff;
background-color: #72131e;


}


.cursos{
    display: flex;
    align-items: center;
    padding: 50px;
    height: 100%;
    width: 100%;
    background-color: #1c2b6c;
    background-size: cover;
       
}
.cursos-h1{
  text-align: center;
  font-family: 'Rowdies', cursive;
 
}

.container-curso {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  
}

.card-curso{
  position: relative;
  color: #ffffff;
  cursor: pointer;
  width: 22rem;
  text-align: center;
  
  
  
}
.title>p{
  position: relative;
  font-family: 'Rowdies', cursive;
  
}
.title>p::before{
  position: absolute;
  content: '';
  width: 95px;
  height: 2px;
  background-color: #ffffff;
  bottom: -10px;
  margin-left: 70px;
  
  
}

.imagem{
  opacity: 1;
  display: block;
  transition: all 0.3s ease-in-out;
  width:22rem;
  height: 18rem;
  border-radius: 30px 0 30px 0;

}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.card-curso:hover .imagem {
  opacity: 0.3;
}

.card-curso:hover .middle {
  opacity: 1;
}

.text-curso {
  background-color: #0b0c0c41;
  color: white;
  width: 22rem;
  height: 18rem;
  font-size: 14px;
  padding: 30px;
  border-radius: 20px 0 20px 0;
   
}

.cursos-p{
  font-family: 'Rowdies', cursive;
  text-align: center;
  font-size: 1rem;
  color: #ffffff;
 
}
.cursos-p::before{

  text-align: center;
  position: absolute;
  content: '';
  width: 60px;
  height: 2px;
  background-color: #ffffff;
  bottom: -7px;
  margin-left: 60px;
  
  
  }
.cursos-h1{
  color: #ffffff;
}



.btn-cursos {
  color: #ffffff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b22234;
  border: 2px solid #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 150px;
  height: 65px;
  margin: 40px auto;
}

.btn-cursos a {
  text-decoration: none;
  color: #ffffff;
}

.btn-cursos:hover {
  background-color: #ffffff;
  border: 2px solid #b22234;
}

.btn-cursos:hover a {
  color: #b22234;
}

@media (max-width: 800px) {
 .cursos{
  display: flex;
  flex-direction: column;
  
 }

     
} 
 





*{
    margin: 0;
    padding: 0;
}

/* ------ Sobre -----*/

.principal{
    background-color: #ffff;
}

.geral{
    
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 50px 40px;
    
}

.sobre-mim{
    width: 30rem;
    height: 30rem;

}

.titulo{
    width: 250px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 30px;
    border-bottom: 2px solid #b22234;
    line-height: 1.8;
    color: #b22234;
}


.texto{
    width: 18rem;
   
}





*{
    margin: 0;
    padding: 0;
}

.container-valores{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
}
.linha{
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 10px;
    border-radius: 5px;
    margin-bottom: 50px;
   
    
}


.link-valores{
padding: 0.5rem 2.5rem;
background-color:#b22234;
border-radius: 20px;
color: #ffff;

}

.link-valores a{
    text-decoration: none;
    color: #ffff;
}
