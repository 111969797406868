*{
    margin: 0;
    padding: 0;
}

/* ------ Sobre -----*/

.principal{
    background-color: #ffff;
}

.geral{
    
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 50px 40px;
    
}

.sobre-mim{
    width: 30rem;
    height: 30rem;

}

.titulo{
    width: 250px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 30px;
    border-bottom: 2px solid #b22234;
    line-height: 1.8;
    color: #b22234;
}


.texto{
    width: 18rem;
   
}




