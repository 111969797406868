.template {
    min-height: 100vh; /* Evita que a header seja empurrada para fora */
    background-color: #1c2b6cf9;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  
}
/* Container geral */
.div-geral-carrossel {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   
  
}

/* Alinha imagem e texto lado a lado */
.content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    gap: 20px;
    
}

/* Imagem ocupa metade da div e fica dentro do template */
.img-container {
   
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
   
}

/* Faz a imagem se ajustar corretamente */
.img-carrossel {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajusta a imagem para cobrir a área sem distorcer */
    max-width: 100%;
    max-height: 100%;
    
}

/* Texto ocupa a outra metade */
.text-container {
   
  
    text-align: center;
    padding: 20px;
}
.text-container h2 {
    margin: 10px 0;
  }
  
.text1-carrossel {
    
    font-size: 50px;
    font-weight: 800;
        
}

.text2-carrossel {
   
    font-size: 60px;
    font-weight: 800;
   
    
}

.text3-carrossel {
    
    font-size: 40px;
     
}

.btn-home {
    color: #ffffff;
    font-weight: 700;
    background-color: #b22234;
    border: 10px groove rgba(170, 36, 53, 1);
    border-radius: 25px;
    box-shadow: 0px 10px 28px 10px rgba(255, 255, 255, 1);
    width: 18.75rem;
    height: 7.5rem;
    margin-top: 2rem;
}

.btn-home a {
    font-size: 2em;
    text-decoration: none;
    color: #ffffff;
}

.btn-home:hover {
    background-color: #ffffff;
    border: 10px groove #ece6e7;
}

.btn-home:hover a {
    color: #b22234;
}

#btn-flutuante{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0.5em;
    bottom: 0.5em;
    z-index: 1;
    cursor: pointer;
    text-align: center;
    font-size: 4em;
    padding: 5px;
    transition: font-size 0.5s;
}

#btn-flutuante a{
    text-decoration: none;
    color: #ffffff;
}

#btn-flutuante:hover{
   font-size: 5em;   
}


@media (max-width: 800px) {

    .div-geral-carrossel {
      flex-wrap: wrap;
       
      
    }
    
    
   
    .text1-carrossel {
        font-size: 30px;
        text-align: center;
    }
    .text2-carrossel {
        font-size: 40px;
        text-align: center;
    }
    .text3-carrossel {
        font-size: 20px;
        text-align: center; 
    }

    .btn-home {
        color: #ffffff;
        font-weight: 700;
        background-color: #b22234;
        border: 10px groove rgba(170, 36, 53, 1);
        border-radius: 25px;
        box-shadow: 0px 10px 28px 10px rgba(255, 255, 255, 1);
        width: 18rem;
        height: 7.5rem;
        margin-top: 2rem;
    }
    
   
    

}

