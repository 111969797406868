.motivacao {
  background-color: #ffff;
  padding: 50px;
  word-break: break-word;
}

.motivacao > h1 {
  font-family: "Rowdies", cursive;
  font-size: 3rem;
  text-align: center;
}

.motivacao-h1 {
  font-family: "Rowdies", cursive;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 50px;
  color: #b22234;
}
.container-motivacao{
   display: flex;
   align-items: center;
   justify-content: center;
}

.row-container{
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: center;

 

}

.img-motivacao,
.caption-motivacao {
  display: inline-block;
  padding: 10px;
 
}

.caption-motivacao{
   width: 200px;
} 

.img-figura {
  
  box-shadow: 2px 2px 2px 0 #1a12132a;
  border-radius: 20px;
  margin-left: 80px;
  font-family: "Rubik", sans-serif;
  
} 

.div-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.button-motivacao {
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
  background-color: #b22234;
  border: 2px solid #ffffff;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 84px;
  margin-left: 30px;
}

.button-motivacao a {
  text-decoration: none;
  color: #ffffff;
}

.button-motivacao:hover {
  background-color: #b22234;
  border: 2px solid #ffffff;
}
.button-motivacao:hover a {
  color: #ffffff;
}

@media (max-width: 800px) {
  .img-figura {
    margin-right: 80px;
   }
   .row-container{
   
    text-align: center;
   }
}